import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { MdSend } from "react-icons/md"
import Helmet from "react-helmet"

export default ({ ...props }) => (
  <Form action="https://formspree.io/f/xbjbjzjp" method="POST">
    <Helmet>
      <meta name="referrer" content="origin" />
    </Helmet>
    <Wrapper>
      <Col
        css={css`
          grid-row-end: span 2;
        `}
      >
        <Title>Poser une question</Title>

        <P>
          Vous souhaitez prendre RDV ou poser une question concernant{" "}
          {props.about}&nbsp;:
        </P>
      </Col>

      <Col>
        <Input
          type="textarea"
          id="nom"
          name="nom"
          aria-label="Votre prénom et nom"
          placeholder="Votre prénom et nom"
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault()
          }}
          required
        />
      </Col>

      <Col>
        <Input
          type="email"
          id="coordonnees"
          name="coordonnees"
          aria-label="Votre adressse email"
          placeholder="Votre adressse email"
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault()
          }}
          required
        />
      </Col>

      <Textarea
        type="textarea"
        id="message"
        name="message"
        aria-label="Votre message"
        wrap="hard"
        placeholder="Votre question..."
        css={css`
          grid-column: 2 / span 2;
        `}
      />

      <input
        type="hidden"
        name="_next"
        value="https://www.docteurphilipperoure.com/message-envoye/"
      />
      <input type="hidden" name="_cc" value="dr.roure@orange.fr" />
      <input type="hidden" name="_language" value="fr" />
      <input
        name="page"
        type="text"
        defaultValue={props.title}
        css={css`
          display: none;
        `}
      />

      <Button type="submit" value="Envoyer">
        <MdSend
          css={css`
            transform: translateY(2px);
          `}
        />{" "}
        Envoyer
      </Button>
    </Wrapper>
  </Form>
)

const Wrapper = styled.div`
  width: 100%;
  max-width: 1150px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 1.5em;
  row-gap: 10px;
  padding: 4em 0 3em;
  @media (max-width: 678px) {
    display: block;
    padding: 2em 2px;
  }
`

const Col = styled.div``

const Title = styled.div`
  padding-top: 0.2em;
  margin-bottom: 0.5em;
  font-weight: 600;
  color: rgba(44, 44, 44, 0.96);
  font-size: 1.4em;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  font-family: Raleway, "Times new roman", serif;
`

const P = styled.p`
  font-size: 0.95em;
  line-height: 1.55em;
  padding-right: 2em;
`

const Form = styled.form`
  display: block;
`

const Input = styled.input`
  margin: 0.5em 0 0.5em;
  width: 89%;
  font-size: 14px;
  letter-spacing: 0em;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 218, 218);
  border-image: initial;
  padding: 0.45em 0.55em;
  :focus {
    border: 1px solid #e6ae48;
    box-shadow: rgba(230, 174, 72, 0.15) 0px 1px 7px;
    outline: none;
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 678px) {
    width: 100%;
    padding: 0.5em;
    box-sizing: border-box;
  }
`

const Textarea = styled.textarea`
  box-sizing: content-box;
  display: block;
  width: 95%;
  height: 100px;
  margin: 0;
  display: block;
  letter-spacing: 0.025em;
  border-radius: 4px;
  border: 1px solid #dadada;
  height: 100px;
  min-height: 100px;
  margin: 0em 0 15px;
  font-size: 14px;
  padding: 0.45em 0.55em;
  resize: none;
  :focus {
    border: 1px solid #e6ae48;
    box-shadow: rgba(230, 174, 72, 0.15) 0px 1px 7px;
    outline: none;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 678px) {
    width: 100%;
    margin-top: 5px;
    padding: 0.5em;
    box-sizing: border-box;
    height: 150px;
  }
`

const Button = styled.button`
  grid-column: 2;
  width: 132px;
  padding: 0.6em 1.2em;
  background-color: #d69c31;
  border: 1px solid #d69c31;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.95em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  :hover,
  :focus {
    color: white;
    background-color: rgba(44, 44, 44, 0.96);
    border: 1px solid rgba(44, 44, 44, 0.96);
    outline: none;
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 678px) {
    float: right;
  }
`
